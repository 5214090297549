import React from 'react';

const Modal = ({ isOpen, onClose, children }: any) => {
  const modalClass = isOpen ? 'modal modal-open' : 'modal';

  return (
    <div className={modalClass}>
      <div className="modal-content">
        <button
          className="modal-close"
          onClick={onClose}
          style={{ float: 'right', display: 'none' }}
        >
          Close
        </button>
        {/* <div style={{ clear: 'both' }}></div> */}
        {children}
      </div>

      <div
        onClick={onClose}
        style={{
          position: 'absolute',
          inset: 0,
          zIndex: -1,
        }}
      />
    </div>
  );
};

export default Modal;
