import React, { useState, useEffect, useRef } from 'react';
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import Layout from '../components/Layout';

import '../styles/NewPageStyles.css';
import whatsAppImage from '../assets/whatsapp.svg';
import instagramImage from '../assets/instagram.svg';
import facebookImage from '../assets/facebook.svg';
import twitterImage from '../assets/twitter.svg';
import Modal from '../components/Model';

const MapNew = (props: any) => {
  // const queryParams = new URLSearchParams(location.search);
  // const imagePath = queryParams.get('imagePath');
  // const content = queryParams.get('content');
  const { content, imagePath } = props?.location?.state || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [images, setImages] = useState([]);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getData = localStorage.getItem('ImagesMaps');
    if (getData !== null) {
      setImages(JSON.parse(getData));
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal1 = () => {
    setIsModalOpen1(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  return (
    <Layout fullWidth>
      {content ? (
        <div>
          <div className="map-container">
            <div>
              <img id="image_id" src={`../${imagePath}`} />

              <div style={{ textAlign: 'center' }}>
                <svg
                  width="54"
                  height="53"
                  viewBox="0 0 54 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  id="open-modal1"
                  onClick={openModal1}
                >
                  <g filter="url(#filter0_dd_244_57)">
                    <rect
                      x="5"
                      y="5"
                      width="45"
                      height="41"
                      rx="3"
                      fill="white"
                    />
                  </g>
                  <path
                    d="M38 22H32V13H23V22H17L27.5 32.5L38 22ZM17 35.5V38.5H38V35.5H17Z"
                    fill="black"
                  />
                  <defs>
                    <filter
                      id="filter0_dd_244_57"
                      x="0"
                      y="0"
                      width="54"
                      height="53"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_244_57"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="-1" dy="3" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_244_57"
                        result="effect2_dropShadow_244_57"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_244_57"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>

                <svg
                  width="54"
                  height="53"
                  viewBox="0 0 54 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  id="open-modal"
                  onClick={openModal}
                >
                  <g filter="url(#filter0_dd_244_58)">
                    <rect
                      x="5"
                      y="5"
                      width="45"
                      height="41"
                      rx="3"
                      fill="white"
                    />
                  </g>
                  <path
                    d="M34.3542 31.1974C33.4242 31.1974 32.5922 31.6065 31.9559 32.2473L23.2315 26.589C23.2927 26.2754 23.3417 25.9618 23.3417 25.6345C23.3417 25.3073 23.2927 24.9937 23.2315 24.6801L31.858 19.0763C32.5188 19.7581 33.3875 20.1807 34.3542 20.1807C36.3854 20.1807 38.025 18.3537 38.025 16.0904C38.025 13.827 36.3854 12 34.3542 12C32.323 12 30.6833 13.827 30.6833 16.0904C30.6833 16.4176 30.7323 16.7312 30.7935 17.0448L22.167 22.6486C21.5063 21.9668 20.6375 21.5442 19.6708 21.5442C17.6396 21.5442 16 23.3712 16 25.6345C16 27.8979 17.6396 29.7249 19.6708 29.7249C20.6375 29.7249 21.5063 29.3022 22.167 28.6205L30.8791 34.2925C30.8179 34.5788 30.7812 34.8788 30.7812 35.1787C30.7812 37.3739 32.3842 39.16 34.3542 39.16C36.3242 39.16 37.9271 37.3739 37.9271 35.1787C37.9271 32.9836 36.3242 31.1974 34.3542 31.1974Z"
                    fill="black"
                  />
                  <defs>
                    <filter
                      id="filter0_dd_244_58"
                      x="0"
                      y="0"
                      width="54"
                      height="53"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_244_58"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="-1" dy="3" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_244_58"
                        result="effect2_dropShadow_244_58"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_244_58"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>

                <svg
                  width="54"
                  height="53"
                  viewBox="0 0 54 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  id="mail-button"
                  onClick={() => {
                    const emailAddress = 'recipient@example.com';
                    const mailtoUrl = 'mailto:' + emailAddress;
                    window.open(mailtoUrl);
                  }}
                >
                  <g filter="url(#filter0_dd_244_59)">
                    <rect
                      x="5"
                      y="5"
                      width="45"
                      height="41"
                      rx="3"
                      fill="white"
                    />
                  </g>
                  <path
                    d="M38.875 14H15.875C14.2937 14 13.0144 15.2937 13.0144 16.875L13 34.125C13 35.7062 14.2937 37 15.875 37H38.875C40.4562 37 41.75 35.7062 41.75 34.125V16.875C41.75 15.2937 40.4562 14 38.875 14ZM38.875 19.75L27.375 26.9375L15.875 19.75V16.875L27.375 24.0625L38.875 16.875V19.75Z"
                    fill="black"
                  />
                  <defs>
                    <filter
                      id="filter0_dd_244_59"
                      x="0"
                      y="0"
                      width="54"
                      height="53"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_244_59"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="-1" dy="3" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_244_59"
                        result="effect2_dropShadow_244_59"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_244_59"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>

                <svg
                  width="54"
                  height="53"
                  viewBox="0 0 54 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  id="print-button"
                  onClick={() => window.print()}
                >
                  <g filter="url(#filter0_dd_244_60)">
                    <rect
                      x="5"
                      y="5"
                      width="45"
                      height="41"
                      rx="3"
                      fill="white"
                    />
                  </g>
                  <path
                    d="M35.4389 20.3056H17.7833C15.6899 20.3056 14 21.9954 14 24.0889V31.6556H19.0444V36.7H34.1778V31.6556H39.2222V24.0889C39.2222 21.9954 37.5323 20.3056 35.4389 20.3056ZM31.6556 34.1778H21.5667V27.8722H31.6556V34.1778ZM35.4389 25.35C34.7453 25.35 34.1778 24.7825 34.1778 24.0889C34.1778 23.3953 34.7453 22.8278 35.4389 22.8278C36.1325 22.8278 36.7 23.3953 36.7 24.0889C36.7 24.7825 36.1325 25.35 35.4389 25.35ZM34.1778 14H19.0444V19.0444H34.1778V14Z"
                    fill="black"
                  />
                  <defs>
                    <filter
                      id="filter0_dd_244_60"
                      x="0"
                      y="0"
                      width="54"
                      height="53"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-1" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_244_60"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="-1" dy="3" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_244_60"
                        result="effect2_dropShadow_244_60"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_244_60"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
          </div>

          <div className="map-content">
            <div>
              <p id="content">{content}</p>
            </div>
          </div>

          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div id="icon-modal" className="" ref={modalRef}>
              <div className="modal-content1">
                <h2>Social Share:</h2>
                <div className="icon" id="facebook-icon">
                  {/* <FacebookShareButton
                url={`${
                  typeof window !== 'undefined' ? window.location.href : ''
                }`}
              > */}
                  <img
                    src={facebookImage}
                    onClick={() => {
                      const url = 'https://www.facebook.com/';
                      window.open(url);
                      setIsModalOpen(false);
                    }}
                    alt="Facebook Icon"
                    width="56"
                    height="56"
                  />
                  {/* </FacebookShareButton> */}
                </div>
                <div className="icon" id="whatsapp-icon">
                  {/* <WhatsappShareButton
                url={`${
                  typeof window !== 'undefined' ? window.location.href : ''
                }`}
              > */}
                  <img
                    src={whatsAppImage}
                    onClick={() => {
                      const url = 'https://web.whatsapp.com/';
                      window.open(url);
                      setIsModalOpen(false);
                    }}
                    alt="whatsapp Icon"
                    width="56"
                    height="56"
                  />
                  {/* </WhatsappShareButton> */}
                </div>
                <div className="icon" id="instagram-icon">
                  {/* <InstapaperShareButton
                url={`${
                  typeof window !== 'undefined' ? window.location.href : ''
                }`}
              > */}
                  <img
                    src={instagramImage}
                    onClick={() => {
                      const url = 'https://www.instagram.com/';
                      window.open(url);
                      setIsModalOpen(false);
                    }}
                    alt="Instagram Icon"
                    width="56"
                    height="56"
                  />
                  {/* </InstapaperShareButton> */}
                </div>
                <div className="icon" id="twitter-icon">
                  {/* <TwitterShareButton
                url={`${
                  typeof window !== 'undefined' ? window.location.href : ''
                }`}
              > */}
                  <img
                    src={twitterImage}
                    onClick={() => {
                      const url = 'https://twitter.com/home';
                      window.open(url);
                      setIsModalOpen(false);
                    }}
                    alt="twitter Icon"
                    width="56"
                    height="56"
                  />
                  {/* </TwitterShareButton> */}
                </div>
              </div>
            </div>
          </Modal>
          <Modal isOpen={isModalOpen1} onClose={closeModal1}>
            <div id="icon-modal2" className="modal1" ref={modalRef}>
              <div className="modal-content1">
                <h2>Download:</h2>
                <div className="icon" id="image-container">
                  {images?.map((item: any) => (
                    <a href={item} download={item}>
                      {item.split('/')[1]}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <h1>Not Found</h1>
      )}
    </Layout>
  );
};

export default MapNew;
